import blackArrow from 'resources/images/black-arrow.svg';

const AdBanner = () => {
  return (
    <a
      className="w-ful  z-20 top-banner flex items-center justify-center gap-2 px-4 py-3"
      href="https://app.symbiotic.fi/restake/manta"
      rel="noreferrer"
      target="_blank">
      <div className="text-[16px] font-[500] text-black">
        From March onwards, you will be able to stake Manta tokens in our new
        staking system to earn rewards.
        <br />
        Starting from January 9th, pre-staking will be available through the
        Symbiotic. Unstake your manta and pre stake here.
      </div>
      <img src={blackArrow} alt="" />
    </a>
  );
};

export default AdBanner;
